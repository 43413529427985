<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
    </div>

    <b-card-code title="Email Discoverd">
      <div class="d-flex justify-content-center mb-1" v-if="loading">
        <b-spinner class="float-right" label="Floated Right" />
      </div>
      <div v-if="!loading">
        <b-table
          id="emailTable"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          v-if="items.length"
          hover
          responsive
          style="min-height: 200px"
        >
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
        </b-table>
        <div v-else>
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-5"
          >
            <feather-icon icon="MailIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No Emails Discoverd</h3>
          </div>
        </div>
      </div>
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";

import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BFormTextarea,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BSpinner,
  },
  data() {
    return {
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        { key: "index", label: "#" },
        { key: "email", label: "Email" },
      ],
      loading: false,
    };
  },
  props: {
    scan_id: {
      type: Number,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  watch: {
    scan_id: function (old, newV) {
      this.loadEmails();
    },
  },
  created: function () {
    if (this.scan_id) {
      this.loadEmails();
    }
  },
  methods: {
    loadEmails: function () {
      this.loading = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/scan-details?id=" +
          this.scan_id,
      };
      this.$http(options).then((res) => {
        const data = JSON.parse(res.data);
        this.items = [];
        // Flatten the emails array and create a new object for each email
        data.forEach((item) => {
          item.scan_result.emails.forEach((email) => {
            this.items.push({ email });
          });
        });
        this.rows = res.data.length;
        this.loading = false;
      });
    },
  },
};
</script>