<template>
  <div>
    <!-- <p>{{organization}}organisation</p> -->
    <b-card-code no-body>
      <!-- <div class="row">
        <div class="d-flex my-1 w-25">
          <v-select
            class="ml-1"
            v-if="is_staff"
            v-model="organization"
            label="text"
            placeholder="--Select Organization--"
            :options="organizations"
            @input="searchFn"
            autocomplete
            style="width: 100%"
            :reduce="organization => organization.value"
          />
        </div>
      </div> -->
      <!-- responsive -->
      <b-table
        id="atTable"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        v-if="!loading"
        hover
        responsive
        style="min-height: 200px;"
      >
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(scan_status)="data">
          <b-badge variant="success" v-if="data.item.scan_status == 0"
            >Completed</b-badge
          >
          <b-badge variant="warning" v-else-if="data.item.scan_status == 1"
            >In Progress</b-badge
          >
          <b-badge variant="success" v-else-if="data.item.scan_status == 2"
            >Completed</b-badge
          >
          <b-badge variant="danger" v-else>Failed</b-badge>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            size="sm"
            class="ml-1"
            variant="outline-primary"
            text="Actions"
          >
            <!-- <b-dropdown-item @click="refresh(data.item.scan_id)"
              >Refresh</b-dropdown-item
            > -->
            <!-- <b-dropdown-item
              v-if="data.item.scan_status == 2"
              @click="assets_discovered(data.item.scan_id)"
              >Assets Discovered</b-dropdown-item
            > -->
            <!-- <b-dropdown-divider /> -->
            <b-dropdown-item @click="gotoDelete(data.item.scan_id,data.item.domain)"
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>

      <b-pagination-nav
        v-model="currentPage"
        :numberOfPages="total"
        :total-rows="rows"
        :link-gen="linkGen"
        :per-page="perPage"
        :align="pagination_pos"
        aria-controls="atTable"
        use-router
        v-if="!loading"
      />
      <!-- Right Sidebar starts -->
      <b-sidebar
        id="sidebar-task-handler"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        width="50%"
      >
        <AssetsDiscover :scan_id="scan_id" :closeSidebar="closeSidebar" />
      </b-sidebar>
       <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{domain}} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteGroup()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
    </b-card-code>
    <!-- </b-tab> -->
    <!-- </b-tabs> -->

    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BBadge,
  BSidebar,
  BFormGroup,
  VBToggle,
  BTabs,
  BTab,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AssetsDiscover from "../AssetDiscovery/components/assets_discovered.vue";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    VBToggle,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    BBadge,
    AssetsDiscover,
    BSidebar,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    vSelect,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      // Call orgaization API and return in this format
      scan_id: 0,
      selected_group: 0,
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "domain", label: "Domain" },
        { key: "organization.org_name", label: "Organization" },
        { key: "scan_status", label: "Status" },
        { key: "errors", label: "Errors" },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      organization: !this.$store.state.app.user.is_staff
        ? this.$store.state.app.user.profile.organization
        : this.$store.state.app.org_id,
      // organization: null,
      scheduleorganization: null,
      domain_organization: null,
      organizations: [],
      name_filter: "",
      domain: "",
      loading: false,
      schedule_selected: null,
      schedule_data: [
        { value: null, text: "--Select Schedule--" },
        { value: 5, text: "Once in a month" },
        { value: 6, text: "once in a three-month" },
        { value: 7, text: "once in a six-month" },
        { value: 8, text: "Once in a year" },
      ],
      openDeleteModal: false,
      domain_scan_id: "",
      domain:"",
    };
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
  },
  created: function () {
    this.load();
    this.loadOrg();
    this.searchFn();
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY hh:mm A");
      }
    },
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    loadOrg: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      if (this.is_staff) {
        this.loading = true;
        // Load oragnization drop down
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/organization/get-all-org",
        };
        var self = this;
        this.$http(o_options).then((res) => {
          // console.log(res.data)
          res.data.map(function (value, key) {
            let org = {
              value: res.data[key].org_id,
              text: res.data[key].org_name,
            };
            // console.log(res.data[key].profile.organization)
            self.organizations.push(org);
          });
          this.loading = false;
        });
      } else {
        this.organization = this.$store.state.app.user.profile.organization;
        this.searchFn();
      }
      this.searchFn();
    },

    load: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset-discovery/scan/?query=true&agent_type_id=10" +"&org_id=" + this.organization ,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
      });
    },
    gotoDelete(scan_id,domain) {
      this.openDeleteModal = true;
      this.domain_scan_id = scan_id;
      this.domain = domain;
    },
    deleteGroup: function () {
      // if (confirm("Do you really want to delete this Scan?")) {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset-discovery/scan/" + this.domain_scan_id + "/",
        };
        var self = this;
        this.$http(options).then((res) => {
          if (res.data == "") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully deleted",
                icon: "TrashIcon",
                variant: "success",
              },
            });
            this.openDeleteModal = false;
            this.searchFn();
          }
        });
      // }
    },

    searchFn: function (reset = true) {
      this.is_search_on = false;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "asset-discovery/scan?query=true&agent_type_id=10" +
        "&page=" +
        this.currentPage;

      if (this.organization != null) {
        this.filter.push({ organization: this.organization });
        url = url + "&org_id=" + this.organization;
      }
      if (this.name_filter != null && this.name_filter != "") {
        this.filter.push({ name: this.name_filter });
        url = url + "&name=" + this.name_filter;
      }
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },

    refresh: function (id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/scan/" +
          id +
          "/refresh/",
      };
      var self = this;
      this.$http(options).then((res) => {
        // this.load();
        this.searchFn();
      });
    },
    assets_discovered: function (id) {
      this.scan_id = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    // scanCreate() {
    //   if (this.schedule_selected == null) {
    //     console.log(this.schedule_selected, "if part is heere");
    //     if (!this.is_staff)
    //       this.domain_organization =
    //         this.$store.state.app.user.profile.organization;
    //     let data = {
    //       org_id: this.domain_organization,
    //       domain: this.domain,
    //       agent_type: 3,
    //       scan_created_by: this.userId,
    //     };
    //     const options = {
    //       method: "POST",
    //       data: data,
    //       headers: { "content-type": "application/json" },
    //       url: process.env.VUE_APP_BASEURL + "asset-discovery/scan/",
    //     };
    //     var self = this;
    //     this.$http(options).then((res) => {
    //       console.log(res, " scan response");
    //       if (res.data.scan_id) {
    //         self.load();
    //       }
    //     });
    //   } else {
    //     console.log(this.schedule_selected, "else part here");
    //     if (!this.is_staff)
    //       this.domain_organization =
    //         this.$store.state.app.user.profile.organization;
    //     let data = {
    //       agent_type: 3,
    //       domain: this.domain,
    //       org_id: this.domain_organization,
    //       is_scan_scheduled: 1,
    //       scan_scheduled_type: this.schedule_selected,
    //     };
    //     const options = {
    //       method: "POST",
    //       data: data,
    //       headers: { "content-type": "application/json" },
    //       url: process.env.VUE_APP_BASEURL + "asset-discovery/scan/",
    //     };
    //     var self = this;
    //     this.$http(options).then((res) => {
    //       console.log(res.data, " scan schedule response");
    //       if (res.data.org_id) {
    //         self.loadSchedules();
    //         self.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: "Schedule Successfully created",
    //             icon: "EditIcon",
    //             variant: "success",
    //           },
    //         });
    //       }
    //     });
    //   }
    // },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #04002f !important;
  color: #fff;
}
</style>
<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
[dir] .vs--searchable .vs__dropdown-toggle {
  padding-bottom: 6px !important;
  padding-top: 4px !important;
  // background-color: white;
}
[dir="ltr"] .vs__search,
[dir="ltr"] .vs__search:focus {
  color: #6e6b7b;
}
[dir] .vs--single .vs__selected {
  color: #606777;
}

.dark-layout .vs__search,
[dir="ltr"] .vs__search:focus {
  color: #b4b7bd;
}
.dark-layout .vs--searchable .vs__dropdown-toggle {
  padding-bottom: 6px;
  padding-top: 4px;
  // background-color: #283046;
}
</style>
