<template>
  <div>
    <b-tabs v-model="emailTabIndex">
      <b-tab title="Emails" active>
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <div class="ml-1">
                <b-input-group class="input-group-merge">
                  <!-- <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-prepend> -->
                  <b-form-input
                    placeholder="Search Domain"
                    @change="loadScanResult"
                    v-model="domain_filter"
                    size="sm"
                  />
                  <b-input-group-append>
                    <b-button variant="primary" size="sm"
                      ><feather-icon
                        icon="SearchIcon"
                        class="text-white"
                        @click="loadScanResult"
                    /></b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="ml-1">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    placeholder="Search Email"
                    @change="loadScanResult"
                    v-model="email_filter"
                    size="sm"
                  />
                  <b-input-group-append>
                    <b-button variant="primary" size="sm"
                      ><feather-icon
                        icon="SearchIcon"
                        class="text-white"
                        @click="loadScanResult"
                    /></b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>

            <b-button
              @click="addEmail()"
              variant="primary"
              type="button"
              class="my-1"
              size="sm"
            >
              Add Email
            </b-button>
          </div>
          <div v-if="!loading">
            <b-table
              id="emailTable"
              :fields="fields"
              :items="items"
              responsive
              :per-page="perPage"
              ref="table"
              hover
              style="min-height: 200px"
              v-if="items.length"
            >
              <template v-slot:head(last_scan_date)>
                <div
                  class="d-flex justify-content-between"
                  style="white-space: nowrap; overflow: hidden"
                >
                  <span style="text-overflow: ellipsis">Last Checked At</span>
                </div>
              </template>
              <template #cell(index)="data">
                {{ data.index + 1 + perPage * (currentPage - 1) }}
              </template>
              <template #cell(is_addressed)="data">
                <b-badge variant="success" v-if="data.item.is_addressed == true"
                  >Yes</b-badge
                >
                <!-- <b-badge variant="danger" v-if="data.item.is_addressed == false"
                >-</b-badge
              > -->
                <span v-if="data.item.is_addressed == false">--</span>
              </template>
              <template #cell(is_pwned)="data">
                <b-badge variant="success" v-if="data.item.is_pwned == true"
                  >Yes</b-badge
                >
                <b-badge variant="danger" v-if="data.item.is_pwned == false"
                  >No</b-badge
                >
              </template>
              <template #cell(last_scan_date)="data">
                {{ data.item.last_scan_date | formatdate }}
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  size="sm"
                  class="ml-1"
                  variant="outline-primary"
                  text="Actions"
                >
                  <b-dropdown-item @click="checkBreach(data.item.id)"
                    >Check Breach</b-dropdown-item
                  >
                  <b-dropdown-item @click="markAsAddressed(data.item.id)"
                    >Mark As Addressed</b-dropdown-item
                  >
                  <b-dropdown-item @click="moreDetails(data.item.id)"
                    >Additional Details</b-dropdown-item
                  >
                  <b-dropdown-divider />
                  <b-dropdown-item
                    @click="gotoDelete(data.item.id, data.item.email)"
                    >Delete</b-dropdown-item
                  >
                </b-dropdown>
              </template>
            </b-table>
            <div v-else>
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
              >
                <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
                <h3 class="font-weight-bolder">No Data Found</h3>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center my-1" v-if="loading">
            <b-spinner class="float-right" label="Floated Right" />
          </div>

          <b-pagination-nav
            v-model="currentPage"
            :numberOfPages="total"
            :total-rows="rows"
            :link-gen="linkGen"
            :per-page="perPage"
            :align="pagination_pos"
            aria-controls="emailTable"
            use-router
          />
        </b-card>
        <b-sidebar
          id="sidebar-task-handler"
          sidebar-class="sidebar-lg"
          bg-variant="white"
          shadow
          backdrop
          no-header
          right
          width="50%"
        >
          <Breach :scan_details="scan_details" />
        </b-sidebar>
      </b-tab>
      <!-- <b-tab title="Email Scans" lazy>
        <EmailScans />
      </b-tab> -->
      <b-tab title="Email Discovery Scans" lazy>
        <!-- <b-card-code no-body> -->
        <b-card class="bg-dark-blue">
          <div class="row">
            <div class="col-12">
              <validation-observer #default="{ invalid }">
                <b-form @submit.prevent>
                  <div class="row" v-if="is_staff">
                    <div class="col-4">
                      <b-form-group class="my-1">
                        <b-form-select v-model="organization" disabled>
                          <option
                            :value="org"
                            v-for="(org, o) in organizations"
                            :key="o"
                          >
                            {{ org.name }}
                          </option></b-form-select
                        >
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Domain"
                          rules="required"
                        >
                          <b-form-input
                            class="my-1"
                            id="name"
                            v-model="domain"
                            name="domain"
                            placeholder="Enter your domain"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-2">
                      <button
                        style=""
                        @click="scanCreate()"
                        type="button"
                        class="btn btn-primary my-1"
                        :disabled="invalid"
                      >
                        <span v-if="isloading"
                          ><b-spinner type="border" small></b-spinner> Please
                          wait</span
                        >
                        <span v-else> Scan</span>
                      </button>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-10">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Domain"
                          rules="required"
                        >
                          <b-form-input
                            class="my-1"
                            id="name"
                            v-model="domain"
                            name="domain"
                            placeholder="Enter your domain"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-2">
                      <button
                        style=""
                        @click="scanCreate()"
                        type="button"
                        class="btn btn-primary my-1"
                        :disabled="invalid"
                      >
                        <span v-if="isloading"
                          ><b-spinner type="border" small></b-spinner> Please
                          wait</span
                        >
                        <span v-else> Scan</span>
                        <!-- <span v-else>
                            <feather-icon
                              icon="SearchIcon"
                              class="mr-50"
                            />Search</span
                          > -->
                      </button>
                    </div>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </b-card>
        <!-- </b-card-code> -->

        <!-- <b-car-code> -->
        <EmailHarvest
          ref="emailHarvest"
          :agent_types="agent_types"
          :emailTabIndex="emailTabIndex"
        />
        <!-- </b-car-code> -->
      </b-tab>
    </b-tabs>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete
        <span class="text-primary">" {{ email }} "</span> ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteGroup()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BBadge,
  BSidebar,
  BFormGroup,
  VBToggle,
  BTabs,
  BTab,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Breach from "./breachCheck.vue";
import EmailHarvest from "../Scan/emailHarvest.vue";
import EmailScans from "./emailScans.vue";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    VBToggle,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    BBadge,
    Breach,
    EmailHarvest,
    EmailScans,
    BSidebar,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    agent_types: {
      type: Array,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pagination_pos: "center",
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,

      userId: localStorage.getItem("userid"),
      // Call orgaization API and return in this format
      scan_id: 0,
      selected_group: 0,
      is_staff: this.$store.state.app.user.is_staff,
      super_admin: this.$store.state.app.user.id == 1,
      // organization: null,
      scheduleorganization: null,
      selectedOrganization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.workspaces,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      //  organization: this.$store.state.app.org_id,
      // domain_organization: null,
      // organizations: [{ value: null, text: "--Select Organization--" }],
      name_filter: "",
      domain: "",
      domain_filter: "",
      email_filter: "",

      loading: false,
      email_id: "",

      email: "",
      openDeleteModal: false,
      domain_scan_id: "",

      items: [],
      fields: [
        { key: "index", label: "#" },
        { key: "email", label: "Email" },
        { key: "domain", label: "Domain" },
        { key: "is_pwned", label: "Breached" },
        { key: "last_scan_date", label: "Last Checked At" },
        { key: "is_addressed", label: "Addressed" },
        { key: "actions", label: "Actions" },
      ],
      scan_details: [],
      isloading: false,
      agent_id: null,
      emailTabIndex: 0,
    };
  },
  computed: {
    isOppositeCheckboxDisabled() {
      return (oppositeCheckboxState) => {
        return oppositeCheckboxState;
      };
    },
    organization() {
      return this.organizations.find(
        (org) => org.tenant_uid === this.$store.state.app.org_id
      );
    },
    selectedOrganizationRefid() {
      if (this.organization) {
        return this.organization.tenant_uid;
      }
      return null; // Handle the case when organization is not selected
    },
    selectedOrganizationId() {
      if (!this.super_admin) {
        return this.$store.state.app.organizationId;
      } else if (this.organization) {
        return this.organization.id;
      }
      return null;
    },
  },
  mounted: function () {
    this.loadOrg();
    // this.loadScanResult();
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY");
      }
    },
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.loadScanResult(false);
    },
    tabIndex: function (newVal, oldVal) {
      if (newVal === 2) {
        this.loadScanResult();
      }
    },
    emailTabIndex: function (newVal, oldVal) {
      if (newVal === 0) {
        this.loadScanResult();
      }
    },
  },
  created: function () {
    if (this.emailTabIndex === 0) {
      this.loadScanResult();
    }
    this.$router.replace({ query: null });
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    loadOrg: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
    },

    // breachCheck: function (id) {
    //   this.domain_scan_id = id;
    //   const options = {
    //     method: "GET",
    //     headers: { "content-type": "application/json" },
    //     url:
    //       process.env.VUE_APP_BASEURL +
    //       "asset-discovery/public-emails/" +
    //       this.domain_scan_id +
    //       "/check-breach",
    //   };
    //   this.$http(options).then((res) => {
    //     this.items = res.data.results;
    //   });
    // },

    addEmail() {
      this.$router.push({ name: "Add Email" });
    },

    checkBreach: function (id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/public-emails/" +
          id +
          "/check-breach",
      };
      var self = this;
      this.$http(options).then((res) => {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.loadScanResult();
      });
    },
    moreDetails: function (id) {
      this.email_id = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/public-emails/" +
          this.email_id +
          "/public-email-details",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res);
        self.scan_details = res.data.email_breaches;
        //this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },

    gotoDelete(id, email) {
      this.openDeleteModal = true;
      this.domain_scan_id = id;
      this.email = email;
    },

    deleteGroup: function () {
      // if (confirm("Do you really want to delete this Scan?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/public-emails/" +
          this.domain_scan_id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data == "") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully deleted",
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.openDeleteModal = false;
          this.loadScanResult();
        }
      });
      // }
    },
    markAsAddressed: function (id) {
      const options = {
        method: "POST",
        // data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/public-emails/" +
          id +
          "/mark-as-addressed/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
        this.loadScanResult();
      });
    },

    loadScanResult(reset = true) {
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "asset-discovery/public-emails?query=true" +
        "&page=" +
        this.currentPage;
      if (this.$route.query.breached) {
        url = url + "&breached=1";
      }
      if (this.domain_filter != "") {
        this.filter.push({ domain: this.domain_filter });
        url = url + "&domain=" + this.domain_filter;
      }

      if (this.email_filter != "") {
        this.filter.push({ email: this.email_filter });
        url = url + "&email=" + this.email_filter;
      }

      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },
    scanCreate() {
      this.isloading = true;
      const selectedAgentType = this.agent_types.find(
        (item) => item.agent_type_code === "emailharvester"
      );
      this.agent_id = selectedAgentType
        ? selectedAgentType.agent_type_id
        : null;
      let data = {
        agent_type: this.agent_id,
        org_id: this.selectedOrganizationId,
        domain: this.domain,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset-discovery/scan/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.org_id) {
          this.$refs.emailHarvest.load();
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Schedule Successfully created",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.isloading = false;
        }
      });
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  /* background-color: #04002f !important; */
  background-color: #181e2d !important;
  color: #fff;
}
</style>
