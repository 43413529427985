<template>
  <div>
    <b-card-code title="Details">
      <b-table :items="scan_details" :fields="fields" responsive> 
        <template #cell(modified_date)="data">
        <span>{{ new Date(data.item.modified_date).toLocaleString() }}</span>
      </template>
      </b-table>
      <!-- <div class="w-100">
        <b-card align="center" class="bg-dark-blue h-100">
          <div class="row" >
            <div class="col" v-for="breach in scan_details" :key="breach">
              <b-list-group class="list-group-circle text-left bg-transparent">
                <b-list-group-item class="bg-transparent" >
                  <b>Name:</b>
                  <span>
                    {{breach.name}}
                   </span
                  >
                </b-list-group-item>

            
                <b-list-group-item class="bg-transparent">
                  <b>Path:</b> <span class=""></span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div></b-card
        >
      </div> -->
    </b-card-code>
  </div>
</template>



<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
  BSpinner,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BSpinner,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    scan_details: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      is_staff_login: this.$store.state.app.user.is_staff,
      items: [],
      fields: [
        // { key: "name", label: "Name" },
        { key: "domain", label: "Domain" },
        { key: "breach_date", label: "Breach Date" },
        { key: "modified_date", label: "Modified Date" },
        { key: "description", label: "Description" },
      ],
      scan_details: [],
    };
  },
  //   filters: {
  //     formatdate: function (value) {
  //       if (value) {
  //         return moment(String(value)).format("DD-MM-YYYY hh:mm A");
  //       }
  //     },
  //   },
  mounted() {
    // this.load();
    // this.loadScanResult();
  },
  methods: {
    // loadScanResult() {
    // debugger
    //   const options = {
    //     method: "GET",
    //     headers: { "content-type": "application/json" },
    //     url:
    //       process.env.VUE_APP_BASEURL +
    //       "asset-discovery/public-emails/"+ this.emailId,
    //   };
    //   this.$http(options).then((res) => {
    //     this.items = res.data.results[0].email_breaches;
    //     // this.rows = res.data.results.length;
    //   });
    // },
  },
};
</script>

<style lang="css" >
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #04002f !important;
  color: #fff;
}
[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #283046 !important;
  border: 1px solid rgb(68 101 129) !important;
}
</style>
